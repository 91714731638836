
import { t } from '@/libs_sz/locale'

const getFormatter = (data, value) => {
  return data?.find(t => t.value == value)?.label ?? ''
}

const getValue = value => (value ? String(value) : String(value) === '0' ? '0' : '')
const statusDict = [{
  label: t('lang.gles.base.containerStatusOnWay'),
  value: 0
},
{
  label: t('lang.gles.base.containerStatusOnWayOccupy'),
  value: 10
}
]
const flagList = [
  {
    label: t('lang.gles.common.yes'),
    value: 0
  },
  {
    label: t('lang.gles.common.no'),
    value: 1
  }
]
const targetTypeList = [
  {
    label: t('lang.gles.base.fixedGoodsPosition'),
    value: 1
  },
  {
    label: t('lang.gles.strategy.factoryPosition'),
    value: 2
  },
  {
    label: t('lang.gles.base.movingGoodsPosition'),
    value: 5
  }
]
export const getMoreQueryFormData = (that, row) => {
  const moreQueryData = [
    // 机器人编号
    {
      name: 'robotCode',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.orderManage.robotCode'),
      clearable: true
    },
    // // 机器人系列
    // {
    //   name: 'robotSerial',
    //   span: 12,
    //   component: 'elSelect',
    //   label: that.$t('lang.gles.strategy.robotSeries'),
    //   clearable: true,
    //   data: that.robotTypeList || []
    // },
    // 机器人型号
    {
      name: 'robotModel',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.robotModel'),
      clearable: true,
      data: that.robotModelList || []
    },
    // 单据号
    {
      name: 'receiptNo',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.stock.orderCode'),
      clearable: true
    },
    // 任务类型
    {
      name: 'taskType',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.taskType'),
      clearable: true,
      data: that.taskTypeList || []
    },
    // 任务最后节点
    {
      name: 'taskLastNode',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.stock.taskLastNode'),
      clearable: true,
      data: flagList || []
    },
    // 目标类型
    {
      name: 'targetPositionType',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.targetType'),
      data: targetTypeList || [],
      clearable: true,
      mchange: that.onTargetTypeChange
    },
    // 目标位置
    {
      name: 'targetPositionId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.stock.targetLocation'),
      placeholder: '请先选择目标类型',
      clearable: true,
      disabled: !row.targetPositionType,
      data: that.targetPositionList || []
    },
    // 状态
    {
      name: 'stockStatus',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.status'),
      clearable: true,
      data: statusDict || []
    },
    // 任务开始时间
    {
      name: 'taskStartTime',
      span: 12,
      component: 'elDatePicker',
      label: that.$t('lang.gles.stock.taskStartTime'),
      type: 'datetimerange',
      rangeSeparator: '-',
      clearable: true
    },
    // 机器人货位编码
    {
      name: 'robotGoodsPositionId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.stock.robotGoodsPositionCode'),
      clearable: true,
      data: that.robotGoodsPositionCodeList || []
    },
    // 机器人组件编码
    {
      name: 'robotContainerId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.stock.robotComponentCode'),
      clearable: true,
      data: that.robotComponentCodeList || []
    },
    // 周转容器编码
    {
      name: 'turnoverContainerId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      clearable: true,
      data: that.turnoverContainerCodeList || []
    },
    //  周转容器类型
    {
      name: 'turnoverContainerTypeId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.material.turnoverContainerType'),
      clearable: true,
      data: that.turnoverContainerTypeList || []
    },
    // 物料编码
    {
      name: 'materialId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.material.materialCode'),
      clearable: true,
      data: that.materialCodeList || [],
      mchange(val, item, cb) {
        val ? cb({ materialName: that.materialList.find(item => item.value === val).value }) : cb({ materialName: '' })
      }
    },
    // 物料名称
    {
      name: 'materialName',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.material.materialName'),
      clearable: true,
      data: that.materialList || [],
      mchange(val, item, cb) {
        val ? cb({ materialId: that.materialCodeList.find(item => item.value === val).value }) : cb({ materialId: '' })
      }
    }
  ]
  moreQueryData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 机器人编号
    {
      prop: 'robotCode',
      label: that.$t('lang.gles.orderManage.robotCode'),
      slotName: 'clickSlot',
      isShow: true,
      minWidth: 100
    },
    // 机器人型号
    {
      prop: 'robotModel',
      label: that.$t('lang.gles.strategy.robotModel'),
      isShow: true,
      minWidth: 100
    },
    // 单据号
    {
      prop: 'receiptNo',
      label: that.$t('lang.gles.stock.orderCode'),
      minWidth: 120,
      isShow: true
    },
    // 任务类型
    {
      prop: 'taskType',
      label: that.$t('lang.gles.strategy.taskType'),
      // minWidth: 72,
      formatter(row, column) {
        return getFormatter(that.taskTypeList, row[column])
      },
      isShow: true
    },
    // 任务最后节点
    {
      prop: 'taskLastNode',
      label: that.$t('lang.gles.stock.taskLastNode'),
      formatter(row, column) {
        return getFormatter(flagList, row[column])
      },
      isShow: true,
      minWidth: 120
    },
    // 目标类型
    {
      prop: 'targetPositionType',
      label: that.$t('lang.gles.strategy.targetType'),
      formatter(row, column) {
        return getFormatter(targetTypeList, row[column])
      },
      isShow: true
    },
    // 目标位置
    {
      prop: 'targetPositionCode',
      label: that.$t('lang.gles.stock.targetLocation'),
      isShow: true
    },
    // 开始时间
    {
      prop: 'taskStartTime',
      label: that.$t('lang.gles.stock.taskStartTime'),
      formatter: { type: 'time' },
      width: 160,
      isShow: true
    },
    // 机器人货位
    {
      prop: 'robotComponentGoodsPositionCodes',
      label: that.$t('lang.gles.stock.robotGoodsPosition'),
      slotName: 'robotGoodsPosition',
      width: 160,
      isShow: true
    },
    // 库存数量
    {
      prop: 'stockNum',
      label: that.$t('lang.gles.stock.stockNum'),
      formatter(row, column) {
        return getValue(row[column])
      },
      isShow: true
    },
    // 状态
    {
      prop: 'stockStatus',
      label: that.$t('lang.gles.base.status'),
      minWidth: 72,
      formatter(row, column) {
        return getFormatter(statusDict, row[column])
      },
      isShow: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 按容器查看
export const getRobotTableItems = (that, options) => {
  const robotTableItems = [
    // 机器人货位编码
    {
      prop: 'goodsPositionId',
      label: t('lang.gles.stock.robotGoodsPositionCode'),
      formatter(row, column) {
        return getFormatter(options.goodsPositionList, row[column])
      }
    },
    // 机器人组件编码
    {
      prop: 'containerId',
      label: t('lang.gles.stock.robotComponentCode'),
      formatter(row, column) {
        return getFormatter(that.containerCodeList, row[column])
      }
    },
    // 机器人组件类型
    {
      prop: 'containerTypeId',
      label: t('lang.gles.stock.robotComponentType'),
      formatter(row, column) {
        return getFormatter(that.containerTypeList, row[column])
      }
    },
    // 容器面
    {
      prop: 'containerDirection',
      label: t('lang.gles.base.containerDirection')
    },
    // 货位序号
    {
      prop: 'goodsPositionNum',
      label: t('lang.gles.base.goodsPositionNum')
    },
    // 库存数量
    {
      prop: 'stockNum',
      label: t('lang.gles.stock.stockNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 占用数量
    {
      prop: 'occupyNum',
      label: t('lang.gles.stock.occupyNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 可用数量
    {
      prop: 'availableNum',
      label: t('lang.gles.stock.availableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    }
  ]
  return robotTableItems
}
export const getContainerTableItems = (that, options) => {
  const containerTableItems = [
    // 移动货位
    {
      prop: 'goodsPositionId',
      label: t('lang.gles.base.movingGoodsPosition'),
      formatter(row, column) {
        return getFormatter(options.goodsPositionList, row[column])
      }
    },
    // 移动容器编码
    {
      prop: 'containerArchivesId',
      label: that.$t('lang.gles.common.moveContainerCode'),
      formatter(row, column) {
        return getFormatter(that.containerCodeList, row[column])
      }
    },
    // 移动容器类型
    {
      prop: 'containerTypeId',
      label: that.$t('lang.gles.common.moveContainerType'),
      formatter(row, column) {
        return getFormatter(that.containerTypeList, row[column])
      }
    },
    // 移动货位序号
    {
      prop: 'goodsPositionNum',
      label: that.$t('lang.gles.common.moveGoodsPositionNum')
    },
    // 库存数量
    {
      prop: 'stockNum',
      label: that.$t('lang.gles.stock.stockNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 占用数量
    {
      prop: 'occupyNum',
      label: that.$t('lang.gles.stock.occupyNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 冻结数量
    {
      prop: 'freezeNum',
      label: that.$t('lang.gles.stock.frozenNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 可用数量
    {
      prop: 'availableNum',
      label: that.$t('lang.gles.stock.availableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 货位状态
    {
      prop: 'stockStatus',
      label: that.$t('lang.gles.workflow.propName.goodsPositionStatus'),
      formatter(row, column) {
        return getFormatter(statusDict, row[column])
      }
    }
  ]
  return containerTableItems
}
// childTableItem
export const getChildTableItem = (that, options) => {
  const childTableItems = [
    // 周转容器编码
    {
      prop: 'containerArchivesId',
      label: t('lang.gles.orderManage.turnoverContainerId'),
      formatter(row, column) {
        return getFormatter(that.containerCodeList, row[column])
      }
    },
    // 周转容器类型
    {
      prop: 'containerTypeId',
      label: t('lang.gles.material.turnoverContainerType'),
      formatter(row, column) {
        return getFormatter(that.containerTypeList, row[column])
      }
    },
    // 库存数量
    {
      prop: 'stockNum',
      label: t('lang.gles.stock.stockNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 占用数量
    {
      prop: 'occupyNum',
      label: t('lang.gles.stock.occupyNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 可用数量
    {
      prop: 'availableNum',
      label: t('lang.gles.stock.availableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    }
  ]
  return childTableItems
}
// getGrandSonTableItem
export const getGrandSonTableItem = (that, options) => {
  const grandsonTableItems = [
    // 物料编码
    {
      prop: 'materialId',
      label: that.$t('lang.gles.material.materialCode'),
      formatter(row, column) {
        return getFormatter(options.materialCodeList, row[column])
      }
    },
    // 物料名称
    {
      prop: 'materialId',
      label: that.$t('lang.gles.material.materialName'),
      formatter(row, column) {
        return getFormatter(options.materialList, row[column])
      }
    },
    // 物料条码
    {
      prop: 'materialBarcode',
      label: t('lang.gles.material.materialBarcode')
    },
    // 基本单位
    {
      prop: 'basicUnit',
      label: t('lang.gles.common.basicUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnit_dict, row[column])
      }
    },
    // 计量数量
    {
      prop: 'measuringNum',
      label: t('lang.gles.stock.measuredNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 计量单位
    {
      prop: 'measuringUnit',
      label: t('lang.gles.common.measureUnit'),
      formatter(row, column) {
        return getFormatter(options.measuringUnit_dict, row[column])
      }
    },
    // 物料朝向
    {
      prop: 'materialToward',
      label: t('lang.gles.orderManage.materialToward'),
      formatter(row, column) {
        return getFormatter(options.materialTowardList, row[column])
      }
    },
    // 库存数量
    {
      prop: 'stockNum',
      label: t('lang.gles.stock.stockNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 占用数量
    {
      prop: 'occupyNum',
      label: t('lang.gles.stock.occupyNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 可用数量
    {
      prop: 'availableNum',
      label: t('lang.gles.stock.availableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 批次号
    {
      prop: 'batchNumber',
      label: t('lang.gles.stock.batchNumber')
    },
    // 等级状态
    {
      prop: 'gradeStatus',
      label: t('lang.gles.common.gradeStatus'),
      formatter(row, column) {
        return getFormatter(options.gradeStatusList, row[column])
      }
    },
    // 批属性
    {
      prop: 'batchProperty',
      label: t('lang.gles.material.batchProperty'),
      slotName: 'batchPropertySlot'
    }
  ]
  grandsonTableItems.forEach((item) => (item.showOverflowTooltip = true))
  return grandsonTableItems
}
// 按物料查看
export const getMaterialTableItems = (that, options) => {
  const materialTableItems = [
    // 物料编码
    {
      prop: 'materialId',
      label: t('lang.gles.material.materialCode'),
      formatter(row, column) {
        return getFormatter(options.materialCodeList, row[column])
      }
    },
    // 所属容器
    {
      prop: 'containerArchivesCode',
      label: t('lang.gles.stock.belongingContainer')
    },
    // 所属货位
    {
      prop: 'goodsPositionCode',
      label: t('lang.gles.stock.belongingGoodsPosition')
    },
    // 物料名称
    {
      prop: 'materialId',
      label: t('lang.gles.material.materialName'),
      formatter(row, column) {
        return getFormatter(options.materialList, row[column])
      }
    },
    // 物料条码
    {
      prop: 'materialBarcode',
      label: t('lang.gles.material.materialBarcode')
    },
    // 基本单位
    {
      prop: 'basicUnit',
      label: that.$t('lang.gles.common.basicUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnit_dict, row[column])
      }
    },
    // 计量数量
    {
      prop: 'measuringNum',
      label: t('lang.gles.stock.measuredNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 计量单位
    {
      prop: 'measuringUnit',
      label: that.$t('lang.gles.common.measureUnit'),
      formatter(row, column) {
        return getFormatter(options.measuringUnit_dict, row[column])
      }
    },
    // 物料朝向
    {
      prop: 'materialToward',
      label: t('lang.gles.orderManage.materialToward'),
      formatter(row, column) {
        return getFormatter(options.materialTowardList, row[column])
      }
    },
    // 库存数量
    {
      prop: 'stockNum',
      label: t('lang.gles.stock.stockNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 占用数量
    {
      prop: 'occupyNum',
      label: t('lang.gles.stock.occupyNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 可用数量
    {
      prop: 'availableNum',
      label: t('lang.gles.stock.availableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 批次号
    {
      prop: 'batchNumber',
      label: t('lang.gles.stock.batchNumber')
    },
    // 等级状态
    {
      prop: 'gradeStatus',
      label: t('lang.gles.common.gradeStatus'),
      formatter(row, column) {
        return getFormatter(options.gradeStatusList, row[column])
      }
    },
    // 批属性
    {
      prop: 'batchProperty',
      label: t('lang.gles.material.batchProperty'),
      slotName: 'batchPropertySlot'
    }
  ]
  materialTableItems.forEach((item) => (item.showOverflowTooltip = true))
  return materialTableItems
}

