<template>
  <div>
    <!-- 主表弹框 -->
    <el-dialog
      :title="$t('lang.gles.stock.stockDetails')"
      :close-on-click-modal="false"
      :visible.sync="visible"
      width="80%"
      @closed="reset"
    >
      <el-tabs v-model="activeName" @tab-click="handleClickTabs">
        <el-tab-pane :label="$t('lang.gles.stock.viewByContainer')" name="first">
          <!-- 有机器人及以下三级 -->
          <div v-if="robotTableData.length > 0">
            <m-table
              :table-item="robotTableItem"
              :table-data="robotTableData"
              :extend-config="tableExtendConfig"
            >
              <template v-slot:expand="scope">
                <div v-if="scope.row.movableContainers" class="child-table">
                  <m-table
                    :table-item="containerTableItem"
                    :table-data="scope.row.movableContainers"
                    :extend-config="tableExtendConfig"
                  >
                    <template v-slot:expand="scopes">
                      <div v-if="scopes.row.turnoverContainers" class="child-table">
                        <m-table
                          border
                          size="mini"
                          :table-item="childTableItem"
                          :table-data="scopes.row.turnoverContainers || []"
                          :extend-config="tableExtendConfig"
                        >
                          <template v-slot:expand="scopess">
                            <div class="grandson-table">
                              <m-table
                                border
                                size="mini"
                                :table-item="grandsonTableItem"
                                :table-data="scopess.row.materials || []"
                                :extend-config="grandsonTableExtendConfig"
                              >
                                <template #batchPropertySlot="{ row }">
                                  <batch-property-info :grid-data="row" />
                                </template>
                              </m-table>
                            </div>
                          </template>
                        </m-table>
                      </div>
                      <div v-if="scopes.row.materials" class="child-table">
                        <m-table
                          border
                          size="mini"
                          :table-item="grandsonTableItem"
                          :table-data="scope.row.materials || []"
                          :extend-config="grandsonTableExtendConfig"
                        >
                          <template #batchPropertySlot="{ row }">
                            <batch-property-info :grid-data="row" />
                          </template>
                        </m-table>
                      </div>
                    </template>
                  </m-table>
                </div>
                <div v-else-if="scope.row.turnoverContainers" class="child-table">
                  <m-table
                    border
                    size="mini"
                    :table-item="childTableItem"
                    :table-data="scope.row.turnoverContainers"
                    :extend-config="tableExtendConfig"
                  >
                    <template v-slot:expand="scopes">
                      <div class="grandson-table">
                        <m-table
                          border
                          size="mini"
                          :table-item="grandsonTableItem"
                          :table-data="scopes.row.materials"
                          :extend-config="grandsonTableExtendConfig"
                        >
                          <template #batchPropertySlot="{ row }">
                            <batch-property-info :grid-data="row" />
                          </template>
                        </m-table>
                      </div>
                    </template>
                  </m-table>
                </div>
                <div v-else class="grandson-table">
                  <m-table
                    border
                    size="mini"
                    :table-item="grandsonTableItem"
                    :table-data="scope.row.materials"
                    :extend-config="grandsonTableExtendConfig"
                  >
                    <template #batchPropertySlot="{ row }">
                      <batch-property-info :grid-data="row" />
                    </template>
                  </m-table>
                </div>
              </template>
            </m-table>
          </div>
          <div v-else>
            <!-- 有移动容器和移动货位(有二级数据) -->
            <div v-if="containerTableData.length > 0">
              <m-table
                :table-item="containerTableItem"
                :table-data="containerTableData"
                :extend-config="tableExtendConfig"
              >
                <template v-slot:expand="scope">
                  <div v-if="scope.row.turnoverContainers" class="child-table">
                    <m-table
                      border
                      size="mini"
                      :table-item="childTableItem"
                      :table-data="scope.row.turnoverContainers || []"
                      :extend-config="tableExtendConfig"
                    >
                      <template v-slot:expand="scopes">
                        <div class="grandson-table">
                          <m-table
                            border
                            size="mini"
                            :table-item="grandsonTableItem"
                            :table-data="scopes.row.materials"
                            :extend-config="grandsonTableExtendConfig"
                          >
                            <template #batchPropertySlot="{ row }">
                              <batch-property-info :grid-data="row" />
                            </template>
                          </m-table>
                        </div>
                      </template>
                    </m-table>
                  </div>
                  <div v-if="scope.row.materials" class="child-table">
                    <m-table
                      border
                      size="mini"
                      :table-item="grandsonTableItem"
                      :table-data="scope.row.materials || []"
                      :extend-config="grandsonTableExtendConfig"
                    >
                      <template #batchPropertySlot="{ row }">
                        <batch-property-info :grid-data="row" />
                      </template>
                    </m-table>
                  </div>
                </template>
              </m-table>
            </div>
            <!-- 有移动容器和无移动货位(有二级数据) -->
            <div v-else-if="noGoodsPositionTableData.length > 0">
              <m-table
                size="mini"
                :table-item="childTableItem"
                :table-data="noGoodsPositionTableData"
                :extend-config="tableExtendConfig"
              >
                <template v-slot:expand="scopes">
                  <div class="grandson-table">
                    <m-table
                      size="mini"
                      :table-item="grandsonTableItem"
                      :table-data="scopes.row.materials || []"
                      :extend-config="grandsonTableExtendConfig"
                    >
                      <template #batchPropertySlot="{ row }">
                        <batch-property-info :grid-data="row" />
                      </template>
                    </m-table>
                  </div>
                </template>
              </m-table>
            </div>
            <!-- 无移动容器和移动货位(无二级数据) -->
            <m-table
              v-else
              size="mini"
              :table-item="grandsonTableItem"
              :table-data="noContainerTableData"
              :extend-config="materialTableExtendConfig"
            >
              <template #batchPropertySlot="{ row }">
                <batch-property-info :grid-data="row" />
              </template>
            </m-table>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('lang.gles.stock.viewByMaterial')" name="second">
          <m-table
            :table-item="materialTableItem"
            :table-data="materialTableData"
            :extend-config="materialTableExtendConfig"
          >
            <template #batchPropertySlot="{ row }">
              <batch-property-info :grid-data="row" />
            </template>
          </m-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
// import myTransform from '@/libs_sz/utils/transform'
import BatchPropertyInfo from '@/components/batchPropertyInfo/index.vue'
import { getRobotTableItems, getContainerTableItems, getChildTableItem, getGrandSonTableItem, getMaterialTableItems } from '../data'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'InventoryDetails',
  components: {
    BatchPropertyInfo
  },
  provide: {
    rootPage: {
      isPagination: false
    }
  },
  data() {
    return {
      visible: false,
      activeName: 'first',
      dataSave: {},
      tableExtendConfig: {
        // checkBox: true,
        sortNum: true,
        expand: true
      },
      materialTableExtendConfig: {
        sortNum: true
      },
      robotTableData: [],
      containerTableData: [],
      noGoodsPositionTableData: [],
      noContainerTableData: [],
      materialTableData: [],
      grandsonTableExtendConfig: {
        sortNum: true
      },
      options: {}
    }
  },
  computed: {
    ...mapState('materialManage', ['basicUnitList', 'measuringUnitList', 'materialTowardList']),
    ...mapState('base', ['gradeStatusList', 'containerTypeList', 'containerCodeList']),
    // tableItem
    robotTableItem() {
      return getRobotTableItems(this, this.options)
    },
    containerTableItem() {
      return getContainerTableItems(this, this.options)
    },
    childTableItem() {
      return getChildTableItem(this, this.options)
    },
    grandsonTableItem() {
      const options = {
        basicUnit_dict: this.basicUnitList,
        measuringUnit_dict: this.measuringUnitList,
        materialTowardList: this.materialTowardList,
        gradeStatusList: this.gradeStatusList,
        ...this.options
      }
      return getGrandSonTableItem(this, options)
    },
    materialTableItem() {
      const options = {
        basicUnit_dict: this.basicUnitList,
        measuringUnit_dict: this.measuringUnitList,
        materialTowardList: this.materialTowardList,
        gradeStatusList: this.gradeStatusList,
        ...this.options
      }
      return getMaterialTableItems(this, options)
    }
  },
  created() {
    if (!this.basicUnitList?.length) {
      this.getBasicUnitList()
    }
    if (!this.measuringUnitList?.length) {
      this.getMeasuringUnitList()
    }
    if (!this.materialTowardList?.length) {
      this.getMaterialToward()
    }
    this.$store.dispatch('base/getGradeStatusList')
  },
  methods: {
    ...mapActions('materialManage', ['getBasicUnitList', 'getMeasuringUnitList', 'getMaterialToward']),
    init(row, options) {
      this.visible = true
      this.dataSave = row
      if (options) { this.options = options }
      // 初始化容器类型和容器编码数据(避免出现页面查不到新增的数据)
      this.$store.dispatch('base/getContainerTypeList')
      this.$store.dispatch('base/getContainerCodeList')

      this.$nextTick(async() => {
        const params = {
          id: row.id
        }
        const { data, code } = await this.$httpService(
          this.$apiStore.inventoryList('queryDetailByContainer'),
          params
        )
        if (code) return
        if (data.robotContainers) {
          this.robotTableData = data.robotContainers
        }
        if (data.movableContainers) {
          this.containerTableData = data.movableContainers
        }
        if (data.turnoverContainers) {
          this.noGoodsPositionTableData = data.turnoverContainers
        }
        if (data.materials) {
          this.noContainerTableData = data.materials
        }
      })
    },
    async handleClickTabs(tab) {
      // console.log(tab)
      if (tab.index === '1' && !this.materialTableData.length) {
        const params = {
          id: this.dataSave.id
        }
        try {
          const { data, code } = await this.$httpService(
            this.$apiStore.inventoryList('queryDetailByMaterial'),
            params
          )
          if (code) return
          this.materialTableData = data
        } catch (error) {
          console.log(error)
        }
      }
    },
    reset() {
      this.robotTableData = []
      this.containerTableData = []
      this.noGoodsPositionTableData = []
      this.materialTableData = []
      this.noContainerTableData = []
      this.activeName = 'first'
    }
  }
}
</script>
<style lang="scss" scoped>
.child-table {
  padding: 6px 16px;
}
.grandson-table {
  padding: 6px 16px;
}
</style>
