<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="robotCode"
      :value.sync="baseFormModel.robotCode"
      :placeholder="`${$t('lang.gles.common.pleaseInput')}${$t('lang.gles.interface.robotCode')}`"
      :query-list="moreQueryData"
      :width="'50%'"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @inventoryDetails="inventoryDetails"
        @pageChange="pageChange"
        @selection-change="val => (selectValue = val)"
      >
        <template #clickSlot="{ row }">
          <span class="list-detail-item" @click="inventoryDetails(row)">{{ row.robotCode }}</span>
        </template>
        <template #robotGoodsPosition="{ row }">
          <span v-if="row.robotComponentGoodsPositionCodes">
            <el-tag
              v-for="(item, index) in row.robotComponentGoodsPositionCodes.split(',')"
              :key="index"
              effect="plain"
              type="info"
            >{{ item }}</el-tag>
          </span>
        </template>
      </m-table>
    </el-card>
    <!-- 库存明细 -->
    <inventory-details ref="inventoryDetails" />
  </div>
</template>
<script>
// import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import InventoryDetails from './components/inventoryDetails.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem } from './data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import inventoryMixins from '../inventoryMixins'
import dayjs from 'dayjs'
import myTransform from '@/libs_sz/utils/transform'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'TransportationInventoryList',
  components: {
    // ColumnConfig,
    QueryView,
    MTable,
    InventoryDetails
  },
  mixins: [listMixins, inventoryMixins],
  data() {
    return {
      tableData: [],
      baseFormModel: {
        robotCode: ''
      },
      formModel: {},
      pageData: {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0
      },
      robotComponentCodeList: [],
      robotGoodsPositionCodeList: [],
      targetPositionList: [],
      selectValue: [],
      visible: false
    }
  },
  computed: {
    ...mapState('strategyManage', ['robotTypeList', 'robotModelList', 'taskTypeList']),
    moreQueryData: function() {
      return getMoreQueryFormData(this, { ...this.formModel })
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      const methods = {
        linefeed: this.linefeed
      }
      return getSearchTableItem(this, methods)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter((item, i) => item.name === this.columnConfigList[i]?.name && this.columnConfigList[i]?.isShow)
      }
      return list
    }
  },
  async created() {
    this.tableExtendConfig = {
      ...this.tableExtendConfig,
      checkBox: false,
      operate: []
    }
    this.initSelectList()
    this.doSearch(this.baseFormModel)
  },
  methods: {
    ...mapActions('strategyManage', ['getRobotTypeList', 'getRobotModelList', 'getTaskTypeList']),
    initSelectList() {
      this.getRobotTypeList()
      this.getRobotModelList()
      this.getTaskTypeList()
      this.getTurnoverContainerTypeList()
      this.getTurnoverContainerCodeList()
      this.getRobotComponentCodeList()
      this.getRobotGoodsPositionCodeList()
      this.getMaterialCodeList()
      // this.getFactoryPositionList()
      this.getGoodsPositionList()
    },
    onTargetTypeChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      switch (val) {
        case 2:
          this.getTargetPositionList('getFactoryPositionDictList', 'factoryPositionCode')
          break
        case 1:
          this.getTargetPositionList('getGoodsPositionListByType', 'code', 'fixedGoodsPosition')
          break
        case 5:
          this.getTargetPositionList('getGoodsPositionListByType', 'code', 'movingGoodsPosition')
          break
        default:
          this.targetPositionList = []
      }
      // if (val === 2) {
      //   // 获取库位编码的下拉数据
      //   this.$httpService(this.$apiStore.base('getFactoryPositionDictList')).then(({ data }) => {
      //     this.targetPositionList = data.map(item => { return { label: item.factoryPositionCode, value: item.id } })
      //   })
      // } else if (val === 1) {
      //   // 获取固定货位编码的下拉数据
      //   this.$httpService(this.$apiStore.base('getGoodsPositionListByType'), { goodsPositionType: 'fixedGoodsPosition' }).then(({ data }) => {
      //     this.targetPositionList = data.map(item => { return { label: item.code, value: item.id } })
      //   })
      // } else if (val === 5) {
      //   // 获取移动货位编码的下拉数据
      //   this.$httpService(this.$apiStore.base('getGoodsPositionListByType'), { goodsPositionType: 'movingGoodsPosition' }).then(({ data }) => {
      //     this.targetPositionList = data.map(item => { return { label: item.code, value: item.id } })
      //   })
      // } else {
      //   this.targetPositionList = []
      // }
    },
    // 公共方法
    getTargetPositionList(url, val, param) {
      const params = param ? { goodsPositionType: param } : {}
      this.$httpService(this.$apiStore.base(url), params).then(({ data }) => {
        this.targetPositionList = data.map(item => { return { label: item[val], value: item.id } })
      })
    },
    // 获取机器人组件编码
    async getRobotComponentCodeList() {
      const params = {
        containerTypePatterns: ['containerPatternRobotComponents']
      }
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getContainerCode'), params)
      if (code) return
      this.robotComponentCodeList = myTransform.arrToOptions(data, 'containerArchivesCode', 'id')
    },
    // 获取机器人货位编码
    async getRobotGoodsPositionCodeList() {
      const params = {
        containerTypePatterns: ['containerPatternRobotComponents']
      }
      const { code, data } = await this.$httpService(this.$apiStore.inventoryList('queryRobotGoodsPositionCode'), params)
      if (code) return
      this.robotGoodsPositionCodeList = myTransform.arrToOptions(data, 'code', 'id')
    },
    // 查询
    async doSearch(params, api = 'getTransitationByCode') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.inventoryList(api, this.pageData),
          params
        )
        if (code) return
        this.$store.commit(types.IS_LOADING, false)
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    // 更多查询
    handleMoreSearch(data) {
      if (data.taskStartTime && data.taskStartTime.length > 0) {
        data.taskStartTimeStart = dayjs(data.taskStartTime[0]).format('YYYY-MM-DD HH:mm:ss')
        data.taskStartTimeEnd = dayjs(data.taskStartTime[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      delete data.taskStartTime
      this.formModel = { ...data }
      this.doSearch({ ...data }, 'getTransitationByCondition')
    },
    /**
     * 更多查询重置方法
     * @param {*} data
     */
    handleReset() {},
    // 库存明细弹窗
    inventoryDetails(row) {
      const options = {
        materialCodeList: this.materialCodeList,
        materialList: this.materialList,
        goodsPositionList: this.goodsPositionList
      }
      this.$refs.inventoryDetails.init({ ...row }, options)
    }
  }
}
</script>
